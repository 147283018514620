import {
  Box,
  Button,
  Fade,
  Grid,
  Stack,
} from "@mui/material";
import LogoComponent from "../components/LogoComponent";
import BotMessageComponent from "../components/BotMessage";
import { useContext, useEffect, useState } from "react";
import { sprintf } from "sprintf-js";
import { useNavigate } from "react-router-dom";
import ProgressIndicatorComponent from "../components/ProgressIndicator";
import { TRANSITION_TIME_IN, TRANSITION_TIME_OUT } from "../theme/gaimcontrol";
import UserContext from "../context/UserContext";
import FadeBox from "../components/FadeBox";



interface Question {
  question: string
  options: string[]
  reaction: string
  title: string
}

interface QuestQuestionProps {
  index: number
  intermediateScreen?: number
}

const questions: Question[] = [
  {
    question: "Nice to meet you%s. Before we start this trip, let's get ready and know each " +
      "other better. Shall we? And remember, everything we discuss is just between you and me, so " +
      "please try to be honest. 😉 Let's cut to the chase. Have you ever felt that you might have a " +
      "problem with gambling?",
    options: [
      "No, not me!",
      "Sometimes I do",
      "Often on my mind.",
      "Yes, always."],
    reaction: "Thanks for sharing. Acknowledging you might have a problem is a significant step " +
      "towards understanding and managing any unwanted behavior. By answering this question " +
      "we've already collected the first item for our trip!",
    title: "You earned a badge!",
  },
  {
    question: "And what about others%s? Have people told you that you had a gambling problem, " +
      "regardless of what you think?",
    options: [
      "Not that I recall.",
      "Some people sometimes.",
      "Yes, most of the time.",
      "They always tell me that."],
    reaction: "Sometimes it's hard to listen when we don't like what they're saying. Being able " +
    "to do so with an open mind will ease our trip.",
    title: "Next step",
  },
  {
    question: "But let's go back to us and our feelings%s. Have you ever felt guilty about " +
      "gambling or about what happens when you do it?",
    options: [
      "Nope, never.",
      "Sometimes guilt.",
      "Often guilty.",
      "Always guilty."],
    reaction: "Identifying your feelings is a positive step towards self-understanding and an " +
      "important one in our trip. And believe it or not, we're almost halfway through!",
    title: "You're on track",
  },
  {
    question: "What about your health%s? Have you suffered any health problems, such as stress " +
      "or anxiety, because of gambling?",
    options: [
      "No, I'm healthy as an apple.",
      "Sometimes it causes problems.",
      "Often feel unwell.",
      "Yes, every single time."],
    reaction: "Understanding how gambling affects your health is not easy but will allow us to " +
      "make positive changes. You've advanced a long way already! Keep on!",
    title: "Well done",
  },
  {
    question: "Of course, if we're talking about gambling, we're talking about money. Let's see " +
      "how we managed that%s. Think about the last 12 months, for example. Did you bet more than " +
      "you could really afford to lose?",
    options: [
      "Never.",
      "Sometimes crossed the line.",
      "Most of the time in that situation.",
      "Yes, always."],
    reaction: "Thinking about our limits is a huge step toward healthy gaming. Well done! Our trip " +
      "is almost ready. I commend you for being so honest with me and yourself.",
    title: "Moving forward",
  },
  {
    question: "After gambling, did you come back another day to try to win back your losses%s?",
    options: [
      "No, never did.",
      "Sometimes did.",
      "Most of the time did.",
      "It's my routine."],
    reaction: "Identifying this kind of pattern is really helpful to get where you want to be. " +
      "We are one step closer!",
    title: "Good progress",
  },
  {
    question: "Talking about what we bet%s, have you borrowed money or sold anything in order to bet?",
    options: [
      "No, never.",
      "Did it a few times.",
      "Most of the time.",
      "Always do."],
    reaction: "Recognizing the extremes we go to can be difficult. You are brave to face this " +
      "question. And we are almost there!",
    title: "You're moving forward",
  },
  {
    question: "We're almost done%s, only 2 more to go! We are still talking about the last 12 months. So, tell " +
      "me, has your gambling put your finances or those of your household in a tight spot?",
    options: [
      "No, never affected finances.",
      "Some struggles, but not often.",
      "Most of the time struggling.",
      "Yes, everyday situation."],
    reaction: "Understanding the impact of gambling on your life is vital. Your sincerity is a step toward recovery!",
    title: "Keep going",
  },
  {
    question: "We're almost done%s. We both know that gambling can be exciting. But did you notice if " +
      "you needed to bet more money in order to have the same excitement?",
    options: [
      "No, that didn't happen.",
      "On occasion, yes.",
      "Most of the time, need more.",
      "Yes, always need more."],
    reaction: "Being honest with yourself is a crucial step in this trip. Congratulations on this " +
      "accomplishment! And we've already finished!",
    title: "Full collection!",
  },
]

function QuestQuestion( props: QuestQuestionProps ) {
  const [questionHidden, setQuestionHidden] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [transitionTimeout, setTransitionTimeout] = useState<number>(TRANSITION_TIME_IN)
  const [intermediateScreen, setIntermediateScreen] = useState<number>(props.intermediateScreen === undefined ? -1 : props.intermediateScreen)
  const navigate = useNavigate();
  const { name, score, email, updateUser } = useContext(UserContext);

  useEffect(() => {
    if (props.intermediateScreen === undefined) {
      setIntermediateScreen(-1)
    } else {
      setIntermediateScreen(props.intermediateScreen)
    }
  }, [props.index])

  const onSubmit = ( value: number ) => {
    setDisableSubmit(true)
    var url = "/quest" + (props.index + 1)
    if (props.index >= (questions.length - 1)) {
      url = "/fin"
    }
    updateUser && updateUser(name, score + value, email)
    setTransitionTimeout(TRANSITION_TIME_OUT);
    setQuestionHidden(true);
    setTimeout(() => {
      navigate(url);
      setTransitionTimeout(TRANSITION_TIME_IN);
      setQuestionHidden(false);
      setDisableSubmit(false)
    }, TRANSITION_TIME_OUT);
  }

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <LogoComponent />
      {
        intermediateScreen === -1 ?
          <Stack flexGrow={1} width={"100%"} display={"flex"} alignItems={"center"}>
            <ProgressIndicatorComponent position={props.index + 1}/>
            <Box flexGrow={1}/>
            <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <BotMessageComponent hidden={questionHidden} timeout={transitionTimeout} text={sprintf(questions[props.index].question, name ? ", " + name : "")} />
              <Box flexGrow={1}/>
              <Fade in={(questionHidden !== true)} timeout={transitionTimeout}>
                <Grid container width={'100%'} spacing={2} px={2}>
                  {
                    questions[props.index].options.map((answer, index) => {
                      return (
                        <Grid key={index} item xs={12} sm={6} md={3} display={'flex'} alignItems={'stretch'}>
                          <Button disabled={disableSubmit} onClick={() => onSubmit(index)} variant="contained" fullWidth sx={{height: '100%'}}>{answer}</Button>
                        </Grid>)
                      })
                  }
                </Grid>
              </Fade>
            </Box>      
            <Box flexGrow={1}/>
          </Stack> :
          <FadeBox contentIndex={intermediateScreen} onClick={()=>setIntermediateScreen(-1)}/>
      }
    </Stack>
  )
}

export default QuestQuestion;
