import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import LogoComponent from "../components/LogoComponent";
import { useNavigate } from "react-router-dom";

interface Component404Props {
  homeButton?: boolean
}

function Component404( props: Component404Props ) {
  const navigate = useNavigate()

  return <Stack
    width={"100%"}
    display={"flex"}
    flexDirection={"column"}
    alignItems={"center"}
  >
      <LogoComponent />

      <Grid container width={"100%"} flexGrow={1} spacing={3}>
        <Grid item md={6} sm={12} order={{md: 1, sm: 2, xs: 2}}>
          <Typography mx={10} mt={10} variant="h1">Oops!</Typography>
          <Typography mx={10} mt={5} variant="h2">Nothing to see here</Typography>
          <Typography mx={10} mt={5} variant="body1">Not all those who wander, are lost.
          Take a step back to move forward.</Typography>
          <Button variant="contained" onClick={() => navigate("/")} sx={{marginX: 10, marginY: 5}}>Return Home</Button>
        </Grid>
        <Grid item md={6} sm={12} xs={12} order={{md: 2, sm: 1, xs: 1 }} display={"flex"} justifyContent={"center"}>
          <Box
            width={"100%"}
            flexGrow={1}
            sx={{
              backgroundImage: "url(/wanderer.webp)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              minHeight: {sm: "200px", xs: "200px" }
            }}/>
        </Grid>
      </Grid>
  </Stack>
}

export default Component404;
