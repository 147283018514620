import { createContext, useState } from "react";

interface UserContextType {
  name: string | undefined;
  score: number;
  email: string | undefined;
  updateUser?: ( newName: string | undefined, newScore: number, newEmail: string | undefined ) => void
}

export const UserContext = createContext<UserContextType>({name: undefined, score: 0, email: undefined });

export const UserConextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [score, setScore] = useState(0);
  const [email, setEmail] = useState<string | undefined>(undefined);

  const updateUser = ( newName: string | undefined, newScore: number, newEmail: string | undefined ) : void => {
    setName(newName);
    setScore(newScore);
    setEmail(newEmail);
  }

  return (
    <UserContext.Provider value={{ name, score, email, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
