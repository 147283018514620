import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface FadeBoxProps {
  contentIndex: number;
  onClick?: () => void;
}

interface FadeBoxContent {
    backgroundImageUrl: string;
    text: string;
}

const fadeBoxContent: FadeBoxContent[] = [
  {
    backgroundImageUrl: "hiking-snow.jpg",
    text: "Many think there's something wrong with people who gamble. It's not true. Gambling is designed to be addictive by exploiting natural human emotions and reactions.",
  },
  {
    backgroundImageUrl: "together.webp",
    text: "Feeling alone is common in gambling struggles. You're not alone. We'll help connect you with others who understand.",
  },
  {
    backgroundImageUrl: "ontop.webp",
    text: "Some believe addiction can't be cured. It can. The journey is tough, but possible. We're here with science-backed support.",
  },
]

const FadeBox: React.FC<FadeBoxProps> = ({ contentIndex, onClick }) => {
  const [imageOpacity, setImageOpacity] = useState(1);
  const [textOpacity, setTextOpacity] = useState(0.25);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setImageOpacity(0.1);
      setTextOpacity(1);
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '50px',
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${fadeBoxContent[contentIndex % fadeBoxContent.length].backgroundImageUrl})`,
          transition: 'opacity 2s',
          opacity: imageOpacity,
        }}
      />

      <Stack
        spacing={4}
        sx={{
          p: 4,
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'opacity 2s',
          opacity: textOpacity,
        }}
      >
        <Box flexGrow={1}/>
        <Typography variant="h5" sx={{ color: 'white' }}>
          {fadeBoxContent[contentIndex % fadeBoxContent.length].text}
        </Typography>
        <Box flexGrow={1}/>
        <Button onClick={onClick} variant="contained">Next</Button>
        <Box flexGrow={1}/>
      </Stack>
    </Box>
  );
};

export default FadeBox;
