import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';

interface LogoComponentProps {
  animate?: boolean
  onAnimationComplete?: () => void
}

function LogoComponent( props: LogoComponentProps ) {
  return (
    <motion.div
    initial={props.animate ? {translateY: "50vh"} : undefined}
    animate={props.animate ? {translateY: 0} : undefined}
    transition={props.animate ? {
      duration: 0.5,
      ease: "easeInOut",
      delay: 1.2
    }: undefined}
    onAnimationComplete={props.onAnimationComplete}
  >
    <Stack direction={"row"} m={3} spacing={2} alignItems={"center"}>
      <motion.div
        initial={props.animate ? {rotate: -90} : undefined}
        animate={props.animate ? {rotate: 0} : undefined}
        transition={props.animate ? {
          duration: 0.5,
          ease: "easeInOut"
        }: undefined}
      >
        <Box width={"50px"} component="img" src="/logo_flat_white.svg"/>
      </motion.div>
      <motion.div
        initial={props.animate ? { clipPath: 'inset(0% 100% 0% 0%)' } : undefined}
        animate={props.animate ? { clipPath: 'inset(0% 0% 0% 0%)' } : undefined}
        exit={props.animate ? { x: -200, opacity: 0 } : undefined}
        transition={props.animate ? {duration: 0.5, delay: 0.6} : undefined}
      >
        <Typography fontSize={"30px"}>GaimControl</Typography>
      </motion.div>
    </Stack>
  </motion.div>)
}

export default LogoComponent;
