import { Box, Fade, Grid, Typography } from "@mui/material";

interface BotMessageComponentProps {
  text: string;
  hidden?: boolean;
  timeout: number;
}

function BotMessageComponent( props: BotMessageComponentProps ) {
  return (
    <Fade in={(props.hidden !== true)} timeout={props.timeout}>
    <Grid
      container
      alignItems={'center'}
      justifyContent={'center'}
      direction={"row"}
      width={"100%"}
      p={3}
      pt={0}
      sx={{boxSizing: 'border-box'}}
    >
      <Grid xs={12} sm={1} display={'flex'} justifyContent={'center'}>
        <Box width={"50px"} height={"50px"} my={2} component="img" src="/anna.png"/>
      </Grid>
      <Grid xs={12} sm={11}>
        <Box
          sx={{
            borderRadius: "24px 24px 24px 24px",
            paddingX: "20px",
            paddingY: "12px",
            backgroundColor: "#E2E2E2",
          }}
        >
          <Typography sx={{color: "black"}}>{props.text}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Fade>    
  )
}

export default BotMessageComponent;
