import { Grid, LinearProgress, useMediaQuery } from "@mui/material";

interface ProgressIndicatorComponentProps {
  position: number
  big?: boolean
}

function ProgressIndicatorComponent(props: ProgressIndicatorComponentProps) {
  const isSmUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))

  return (
    <Grid
      container
      columnSpacing={props.big ? 2 : isSmUp ? 4 : 2}
      p={3}
    >
      <Grid item xs={3}>
        <LinearProgress variant="determinate" value={props.position < 2 ? props.position * 50 : 100} sx={{width: '100%'}}></LinearProgress>
      </Grid>
      <Grid item xs={3}>
        <LinearProgress variant="determinate" value={props.position < 2 ? 0 : props.position < 4 ? (props.position % 2) * 50 : 100} sx={{width: '100%'}}></LinearProgress>
      </Grid>
      <Grid item xs={3}>
        <LinearProgress variant="determinate" value={props.position < 4 ? 0 : props.position < 6 ? (props.position % 2) * 50 : 100} sx={{width: '100%'}}></LinearProgress>
      </Grid>
      <Grid item xs={3}>
        <LinearProgress variant="determinate" value={props.position < 6 ? 0 : props.position < 9 ? (props.position % 3) * 34 : 100} sx={{width: '100%'}}></LinearProgress>
      </Grid>
    </Grid>
  )
}

export default ProgressIndicatorComponent;
