import { createTheme } from '@mui/material/styles';
import { formLabelClasses } from "@mui/material";

export const MAX_WIDTH = "800px";
export const BOTTOM_NAVBAR_HEIGHT = "60px";
export const TRANSITION_TIME_IN = 1000
export const TRANSITION_TIME_OUT = 500

const GaimControlTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#D7BAFF',
      contrastText: '#3E1F68',
    },
    secondary: {
      main: '#673AB7',
      contrastText: '#ffffff',
    },
    background: {
      default: '#21263D',
      paper: '#32395d',
    },
    text: {
      primary: '#E9EAEB',
      secondary: '#E5E2E1',
      disabled: '#C4C7C8',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#FB8C00',
      contrastText: '#ffffff',
    },
    success: {
      main: '#385400',
      contrastText: '#b5ff1b',
    },
    info: {
      main: '#0288D1',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
        color: '#ffffff',
    },
    h2: {
        color: '#ffffff',
    },
    h3: {
        color: '#ffffff',
    },
    h4: {
        color: '#ffffff',
    },
    h5: {
        color: '#ffffff',
    },
    h6: {
        color: '#ffffff',
    },
    subtitle1: {
        color: '#e5e2e1',
    },
    subtitle2: {
        color: '#e5e2e1',
    },
    body1: {
        color: '#e9eaeb',
        fontSize: "16px",
        letterSpacing: "0.25px",
    },
    body2: {
        color: '#c4c7c8',
        letterSpacing: "0.25px",
        lineHeight: "20px",
        fontSize: "14px",
    },
    button: {
        fontSize: '1rem',
        letterSpacing: '0.5px',
        color: 'white',
    },
    caption: {
        color: 'white',
    },
    overline: {
        color: 'white',
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
          [`&.${formLabelClasses.focused}`]: {
            color: "white",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
        },
      },
    }
  }
})

export default GaimControlTheme;
